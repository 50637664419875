import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {combineLatest, Subscription} from "rxjs";
import {
  AuthService,
  Cart, CartItem,
  extractErrorMessagesFromResponse,
  MediaSize,
  MediaType,
  ShopyanCartHelperService,
  ShopyanToastrService,
  StorageService,
  urlMedia
} from "@app/_shared";
import {Router} from "@angular/router";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {ShopyanSectionComponent} from "@app/_shared/core/shopyan-section.component";
import {isPlatformBrowser} from "@angular/common";

@Component({
  template: ''
})
export abstract class ShopyanCartComponent extends ShopyanSectionComponent {

  subscription: Subscription = new Subscription();

  cart: Cart;
  tax = 0;

  loading: boolean;

  protected constructor(protected basicHelperService: ShopyanCartHelperService,
                        private basicToastr: ShopyanToastrService,
                        private storageService: StorageService,
                        public authService: AuthService,
                        private http: HttpClient,
                        private router: Router,
                        @Inject(PLATFORM_ID) private platformId: any) {
    super();
  }

  init(): void {
    if (!this.buildMode) {
      if (this.storageService.getData('dis_add_to')) {
        this.router.navigate(['/not-found']).then();
      }

      if(isPlatformBrowser(this.platformId)) {
        this.initCartItems();
        this.initUpsells();
      }
    }
  }

  protected abstract initUpsells(): void;

  initCartItems(): void {
    this.loading = true;
    let cart: any;
    const storageData = this.storageService.getData('car');
    if (storageData) {
      cart = JSON.parse(storageData);
    }
    // Browser only - no private api call
    const storeDataUrl = `${environment.pubicStoreApiUrl}/store/data`;
    let cartDataUrl = `${environment.pubicOrdersApiUrl}/cart/data`;
    if (this.authService.isAuthenticated()) {
      cartDataUrl = `${environment.pubicOrdersApiUrl}/api/cart/data`;
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    // this.subscription.add(combineLatest([
    //   this.http.post(cartDataUrl, this.basicHelperService.getCartFormFromCart(cart), httpOptions),
    //   this.http.get(storeDataUrl, httpOptions)
    // ]).subscribe({
    //   next: ([cartDataResponse, storeDataResponse]: [any, any]) => {
    //     this.loading = false;
    //     this.cart = cartDataResponse;
    //     this.tax = storeDataResponse.tax;
    //     this.refreshTotal();
    //   },
    //   error: (error: any) => {
    //     this.loading = false;
    //     this.basicToastr.error(extractErrorMessagesFromResponse(error));
    //   }
    // }));

    // TODO jaouad temporary, to remove when back is done
    this.cart  = cart;
    this.refreshTotal();
    this.loading = false;
  }

  /**
   * Remove item
   * @param index
   */
  removeItem(index: number): void {
    this.cart.items.splice(index, 1);
    this.refreshTotal();
  }

  /**
   * Refresh quantity & totals
   */
  refreshTotal(): void {
    this.cart.total = 0;
    this.cart.items.forEach(item => {
      if(!item.productBundle) {
        let price = item.product.pricing?.price || 0;
        if (item.variation) {
          price = item.variation.price || 0;
        }
        item.total = item.quantity * price;

      }
      this.cart.total = this.cart.total + this.basicHelperService.getDiscountedPrice(item);
    });

    this.basicHelperService.refreshCart(this.cart);
    this.basicHelperService.synchronizeConnectedCart(this.cart);
  }

  /**
   * Calculate big total
   */
  get bigTotal(): number {

    let total = this.cart.total;

    // Automatic Discounts
    this.cart.discounts?.forEach((item: any) => {
      if (item.value) {
        total -= item.value;
      } else if (item.percent) {
        total -= (item.percent * this.cart.total) / 100;
      }
    });

    // Taxes
    if (this.tax) {
      total += (this.tax * total) / 100;
    }

    return total > 0 ? total : 0;
  }

  /**
   * Tax value
   */
  get taxValue(): number {
    return this.tax ? (this.tax * this.cart.total) / 100 : 0;
  }

  goCheckout(): void {
    this.basicHelperService.refreshCart(this.cart);
    this.router.navigateByUrl(`/checkout`).then();
  }

  urlProductMediumMedia(media: string): string {
    return urlMedia(environment.mediaURL + "/", this.storeID, MediaType.PRODUCT, MediaSize.medium, media);
  }

  urlVariantMediumMedia(media: string): string {
    return urlMedia(environment.mediaURL + "/", this.storeID, MediaType.VARIANT, MediaSize.medium, media);
  }

  getDiscountedPrice(item: CartItem) {
    let price = item.total || 0;
    if (item.upsell) {
      if (item.upsell.fixedDiscount) {
        price -= item.upsell.fixedDiscount;
      } else if (item.upsell.discountPercent) {
        price -= (item.upsell.discountPercent * price) / 100;
      }
    }
    price -= this.basicHelperService.getDiscountValue(item.discounts, price);
    return price > 0 ? price : 0;
  }


}
