<div class="bundle-item" [class.checked]="bundleItem.selected" (click)="checkOffer()">
  <div class="pinned"
       *ngIf="bundleItem.bundle.pinnedText">{{bundleItem.bundle.pinnedText}}</div>
  <div class="bundle-def">
    <mat-radio-button (change)="checkOffer()"
      [checked]="bundleItem.selected"> </mat-radio-button>
    <div class="labels">
      <div class="ttl">{{bundleItem.bundle.title}}</div>
      <div class="lbl" *ngIf="bundleItem.bundle.label">{{bundleItem.bundle.label}}</div>
    </div>
    <div class="values ok">
      <div class="final-value"> {{bundleItem.offerTotal | price}}</div>
      <div class="init-value">{{bundleItem.initTotal | price}}</div>
    </div>
  </div>
  <ng-container *ngIf="loop.length > 0">
  <div class="bundle-variations animated tdFadeInUp" *ngIf="bundleItem.selected" >
    <div class="variation-item" *ngFor="let item of loop; let index= index">
      <div class="variation-lbl">
        # {{index + 1}} :
      </div>
      <mat-form-field appearance="outline">
        <mat-select [(ngModel)]="selectedVariationIds[index]" (selectionChange)="selectVariation($event.value, index)">
          <ng-container *ngFor="let variation of bundleItem.product.variations">
            <mat-option
              [disabled]="variation.disabled || (!bundleItem.product.disableOutOfStock && (!variation.quantity || variation.quantity <= 0))"
              [value]="variation.id">
              <span>{{variation.optionValues.join(' - ') + ' - '}} {{variation.price | price}}</span>
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  </ng-container>
</div>
