import {Product, ProductVariation} from "@app/_shared";

export class BundleOffer {
  id: string;
  type: BundleOfferType;
  quantity: number;
  value: number;
  title: string;
  label: string;
  pinnedText: string;
}

export enum BundleOfferType {
  FIXED = 'FIXED',
  PERCENT = 'PERCENT',
  ARTICLE = 'ARTICLE'
}

export class ProductBundleOffer {
  bundle: BundleOffer;
  variations: ProductVariation[];
  initTotal: number;
  offerTotal: number;
  product: Product;
  selected: boolean;
}
