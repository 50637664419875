import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BundleOfferType, ProductBundleOffer, ProductVariation} from "@app/_shared";

@Component({
  selector: 'app-neo-bundle-item',
  templateUrl: './neo-bundle-item.component.html',
  styleUrls: ['./neo-bundle-item.component.scss']
})
export class NeoBundleItemComponent implements OnInit {

  @Input()
  bundleItem: ProductBundleOffer;

  @Output()
  selectBundle = new EventEmitter();

  @Output()
  updateBundle = new EventEmitter();

  loop: number[] = [];

  selectedVariationIds: string[] = []

  constructor() {
  }

  ngOnInit(): void {
    if (this.bundleItem.variations && this.bundleItem.variations.length > 0) {
      this.loop = Array(this.bundleItem.variations.length).fill(0);
    }
    this.selectedVariationIds = this.bundleItem.variations.map(v => v.id);
    this.refreshTotals(true);
  }


  refreshTotals(initCall?: boolean): void {
    const variations = [...this.bundleItem.variations];

    const hasVariations = variations?.length > 0;
    const price = hasVariations ? variations.reduce((accumulator, variation) => accumulator + variation.price, 0)
      : (this.bundleItem.product.pricing.price * this.bundleItem.bundle.quantity);

    if (this.bundleItem.bundle.type === BundleOfferType.PERCENT) {
      this.bundleItem.initTotal = price;
      this.bundleItem.offerTotal = this.bundleItem.initTotal - (this.bundleItem.initTotal * this.bundleItem.bundle.value / 100);

    } else if (this.bundleItem.bundle.type === BundleOfferType.FIXED) {
      this.bundleItem.initTotal = price;
      this.bundleItem.offerTotal = this.bundleItem.initTotal - this.bundleItem.bundle.value;

    } else {

      if (hasVariations) {
        this.bundleItem.initTotal = price;

        // Sort items by price in ascending order
        const sortedItems = variations.sort((a: ProductVariation, b: ProductVariation) => a.price - b.price);

        // Take the lowest prices
        const totalOfTwoLowestPrices = sortedItems.slice(0, this.bundleItem.bundle.value).reduce((accumulator, item) => accumulator + item.price, 0);

        this.bundleItem.offerTotal = price - totalOfTwoLowestPrices;

      } else {
        this.bundleItem.initTotal = price + (this.bundleItem.product.pricing.price * this.bundleItem.bundle.value);
        this.bundleItem.offerTotal = price;
      }
    }

    this.bundleItem.initTotal = this.bundleItem.initTotal < 0 ? 0 : this.bundleItem.initTotal;
    this.bundleItem.offerTotal = this.bundleItem.offerTotal < 0 ? 0 : this.bundleItem.offerTotal;
    if (!initCall) {
      this.updateBundle.emit();
    }
  }


  selectVariation(variationId: string, index: number): void {
    this.bundleItem.variations[index] = {...this.bundleItem.product.variations.find(v => v.id === variationId)!};
    this.refreshTotals();
  }


  checkOffer(): void {
    if (!this.bundleItem.selected) {
      this.selectBundle.emit();
    }
  }


}
