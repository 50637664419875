<div id="account-orders">
  <app-shopyan-placeholder *ngIf="loading"></app-shopyan-placeholder>
  <ng-container *ngIf="orders && orders.length > 0; else noOrder">
    <div class="order-item" *ngFor="let order of orders">

      <div class="inf">
        <div>
          <div class="order-id">{{order.ref}}</div>
          <div class="order-date">{{order.createdDate | date: 'dd/MM/yyyy HH:mm'}}</div>
        </div>
        <div class="order-total">{{order.total}} {{order.currency}}</div>
      </div>

      <div class="products">
        <div class="order-product" *ngFor="let orderItem of order.orderItems">
          <div class="product-ic">
            <div class="product-count">{{orderItem.quantity}}</div>
            <img alt="store"  [src]="urlProductMediumMedia(orderItem.product?.media)"
                 *ngIf="orderItem.product?.media && (!orderItem.variant || !orderItem.variant.media)"/>
            <img alt="store"  [src]="orderItem.product?.imageLink"
                 *ngIf="orderItem.product?.imageLink && !orderItem.product?.media
                                   && (!orderItem.variant || !orderItem.variant.media)"/>
            <img alt="store"  [src]="urlVariantMediumMedia(orderItem.variant?.media)" *ngIf="orderItem.variant?.media"/>
          </div>
          <div class="product-details">
            <div class="product-title">
              <div class="product-name">{{orderItem.product.name}}</div>
              <div class="variant" *ngIf="orderItem.variant">
                <ng-container *ngFor="let option of orderItem.variant.optionValues">
                  <div class="variant-item">{{option}}</div>
                  <div class="sep">-</div>
                </ng-container>
              </div>
            </div>
            <div class="product-price">{{orderItem.total}} {{order.currency}}</div>
            <div class="product-inf">
              <div class="discounts"
                   *ngIf="orderItem.discounts || (orderItem.upsell && (orderItem.upsell.fixedDiscount || orderItem.upsell.discountPercent) )">
                <ng-container *ngFor="let discount of orderItem.discounts">
                  <div class="discount">
                    {{discount.label}}
                    <span *ngIf="discount.value">-{{+discount.value.toFixed(2)}} {{order.currency}}</span>
                    <span *ngIf="discount.percent">-{{discount.percent}}%</span>
                  </div>
                </ng-container>
                <div class="discount"
                     *ngIf="orderItem.upsell && (orderItem.upsell.fixedDiscount || orderItem.upsell.discountPercent)">
                  <span
                    *ngIf="orderItem.upsell.fixedDiscount">-{{orderItem.upsell.fixedDiscount}} {{order.currency}}</span>
                  <span *ngIf="orderItem.upsell.discountPercent">-{{orderItem.upsell.discountPercent}}
                    %</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="discounts" *ngIf="order.discounts">
        <ng-container *ngFor="let discount of order.discounts">
          <div class="discount">
            {{discount.label}}
            <span *ngIf="discount.value">-{{+discount.value.toFixed(2)}} {{order.currency}}</span>
            <span *ngIf="discount.percent">-{{discount.percent}}%</span>
          </div>
        </ng-container>
      </div>

      <div class="order-more animated tdFadeInUp" *ngIf="order.ref == currentOrderId">
        <div class="order-more-item" *ngIf="order.shippingCosts">
          <span>{{'ACCOUNT.ORDERS.SHIPPING_COST' | translate}}</span>
          <span>{{order.shippingCosts}} {{order.currency}}</span>
        </div>
        <div class="order-more-item" *ngIf="order.taxes">
          <span>{{'ACCOUNT.ORDERS.TAX' | translate}}</span>
          <span>{{order.taxes}} {{order.currency}}</span>
        </div>
        <div class="order-more-item" *ngIf="order.trackingNumber">
          <span>{{'ACCOUNT.ORDERS.TRACKING_NUMBER' | translate}}</span>
          <span>{{order.trackingNumber}}</span>
        </div>
        <div class="order-addresses">
          <div class="order-address" *ngIf="order.shippingAddress">
            <div class="address-lbl">{{'ACCOUNT.ORDERS.SHIPPING_ADDRESS' | translate}}</div>
            <div class="address"><span [innerHTML]="getAddressAsString(order.shippingAddress)"></span></div>
          </div>
          <div class="order-address" *ngIf="order.billingAddress">
            <div class="address-lbl">{{'ACCOUNT.ORDERS.BILLING_ADDRESS' | translate}}</div>
            <div class="address"><span [innerHTML]="getAddressAsString(order.billingAddress)"></span></div>
          </div>
        </div>
      </div>

      <div class="order-footer">
        <div class="status" [ngClass]="order.orderStatus">{{order.orderStatus}}</div>
        <div class="action neo-btn" (click)="toggleOrder(order)"
             *ngIf="order.ref != currentOrderId">{{'ACCOUNT.ORDERS.MORE_DETAILS' | translate}}</div>
        <div class="action neo-btn" (click)="toggleOrder(order)"
             *ngIf="order.ref == currentOrderId">{{'ACCOUNT.ORDERS.LESS_DETAILS' | translate}}</div>
      </div>

    </div>
    <div class="show-more neo-btn" *ngIf="showMore"
         (click)="getOrders(true)">{{'ACCOUNT.ORDERS.SHOW_MORE' | translate}}</div>
  </ng-container>
  <ng-template #noOrder>
    <div class="no-order">{{'ACCOUNT.ORDERS.NO_ORDER' | translate}}</div>
  </ng-template>
</div>
